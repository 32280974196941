import logo from './logo.svg';
import appStore from './AppStore.png';
import './App.css';
import { useParams } from 'react-router-dom';
import {db} from "./firebase";
import {getDocs, collection, query, limit, where } from "firebase/firestore"
import {useState, useEffect} from "react";
import {isMobile} from 'react-device-detect';

export function 
PrayerLanding(){
    const {prayertag} = useParams()
    const [user, setUser] = useState([]);
    var [result, setLoading] = useState([]);
    
    var loading = true
     const queryRef = query(
  collection(db, "users"),
  limit(1),
  where("prayertag", "==", prayertag)
);
          useEffect(() =>{

        const getUser = async () => {
          const querySnapshot = await getDocs(queryRef);
                    setLoading(false)
          setUser(querySnapshot.docs[0].data());
        };

        getUser();
      }, [])


      if (isMobile){
      if (loading && result ){
             return   <div className="App">
      <header className="App-header">
           <img src={logo} className="loadingLogo" alt="logo" />
      </header>
    </div>
      }
      else{
      if (user.uid == null) {
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="noShimmer">
          Hm, try again
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else if (user.public == false){
        document.title = `Pray for ${user.displayName}`;
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="noShimmer">
          User is private 😕
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
          }
      else if (user.prayercode == ""){
                document.title = `Pray for ${user.displayName}`;

             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="noShimmer">
          Awaiting user's Prayercode™
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else{
                document.title = `Pray for ${user.displayName}`;
     return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
                  <p  className="scanTo">
          Scan to pray
        </p>
        <div className="container_row">
            <div className="rectang">
                        </div>

             <img 
      src={user.prayercode}
      className="nuemorphicCodeShimmerless" 
      alt="error"
      />
                 <img src={logo} className="codeLogoShimmerless" alt="logo" />
          </div>
        <h  className="prayertag">
          🙏 for {user.displayName}
        </h>
          <p  className="displayName">
          ⓟ{user.prayertag}
        </p>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadge" alt="logo" />
                </a>
      </header>
    </div>
      }
    }
      }
      else{
      if (loading && result ){
             return   <div className="App">
      <header className="App-header">
           <img src={logo} className="loadingLogo" alt="logo" />
      </header>
    </div>
      }
      else{
      if (user.uid == null) {
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="shimmer">
          Hm, try again
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else if (user.public == false){
        document.title = `Pray for ${user.displayName}`;
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="shimmer">
          User is private 😕
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
          }
      else if (user.prayercode == ""){
                document.title = `Pray for ${user.displayName}`;

             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="shimmer">
          Awaiting user's Prayercode™
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else{
                document.title = `Pray for ${user.displayName}`;
     return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
                  <p  className="scanTo">
          Scan to pray
        </p>
        <div className="container_row">
            <div className="rectang">
                        </div>

             <img 
      src={user.prayercode}
      className="nuemorphicCode" 
      alt="error"
      />
                 <img src={logo} className="codeLogo" alt="logo" />
          </div>
        <h  className="prayertag">
          🙏 for {user.displayName}
        </h>
          <p  className="displayName">
          ⓟ{user.prayertag}
        </p>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadge" alt="logo" />
                </a>
      </header>
    </div>
      }
    }
  }

}