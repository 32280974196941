import logo from './logo.svg';
import fireLogo from './Fire.png';
import appStore from './AppStore.png';
import './App.css';
import { useParams } from 'react-router-dom';
import {db} from "./firebase";
import {getDocs, collection, query, limit, where } from "firebase/firestore"
import {useState, useEffect} from "react";
import {isMobile} from 'react-device-detect';

export function 
FireLanding(){
  const {prayertag, fireName} = useParams()
  const [fire, setFire] = useState([]);
  var [result, setLoading] = useState([]);
  const [user, setUser] = useState([]);
    
  var loading = true
  const userRef = query(
  collection(db, "users"),
  limit(1),
  where("prayertag", "==", prayertag)
  );
                useEffect(() =>{
        const getUser = async () => {
          const querySnapshot = await getDocs(userRef);
          setUser(querySnapshot.docs[0].data());
        };
        getUser();
      }, [])
                useEffect(() =>{
                        if(user.uid == undefined){
      return
    }

                      const prayerRef = query(
    collection(db, "prayerByGroup"),
    limit(1),
    where("fireName", "==", fireName),
    where("access", "==", "Public"),
    where("owner", "==", user.uid)
  );
      const getFire = async () => {
          const querySnapshot = await getDocs(prayerRef);
                    setLoading(false)
          setFire(querySnapshot.docs[0].data());
        };
                getFire();
      }, [user])

      if (isMobile){
      if (loading && result ){
             return   <div className="App">
      <header className="App-header">
           <img src={logo} className="loadingLogo" alt="logo" />
      </header>
    </div>
      }
      else{
      if (fire.owner == null) {
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="noShimmer">
          Hm, try again
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else if (fire.access != "Public"){
        document.title = `Pray for ${user.displayName}`;
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="noShimmer">
          Fire is private 😕
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
          }
      else if (fire.prayercode == ""){
                document.title = `Pray for ${user.displayName}`;
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="noShimmer">
          Awaiting Fire Prayercode™
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else{
                document.title = `Pray for ${fire.fireName}`;
     return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
                  <p  className="scanTo">
          Scan to pray
        </p>
        <div className="container_row">
            <div className="rectang">
                        </div>
             <img 
      src={fire.prayercode}
      className="nuemorphicCodeShimmerless" 
      alt="error"
      />
                 <img src={logo} className="codeLogoShimmerless" alt="logo" />
          </div>
          <div class="flexbox-container">
                 <img src={fire} className="fire" alt="fire" />
                 <h  className="prayertag">{fire.fireName}</h>
</div>
        
          <p  className="displayName">
          ⓟ{user.prayertag}
        </p>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadge" alt="logo" />
                </a>
      </header>
    </div>
      }
    }
      }
      else{
      if (loading && result ){
             return   <div className="App">
      <header className="App-header">
           <img src={logo} className="loadingLogo" alt="logo" />
      </header>
    </div>
      }
      else{
      if (fire.owner == null) {
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="shimmer">
          Hm, try again
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else if (fire.access != "Public"){
        document.title = `Pray for ${user.displayName}`;
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="shimmer">
          Fire is private 😕
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
          }
      else if (fire.prayercode == ""){
                document.title = `Pray for ${user.displayName}`;
             return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
        <h  className="shimmer">
          Awaiting Fire Prayercode™
        </h>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                </a>
                        <img src={logo} className="nuemorphicLogo" alt="logo" />
      </header>
    </div>
      }
      else{
                document.title = `Pray for ${fire.fireName}`;
     return   <div className="App">
      <header className="App-header">
                <a
          href="https://prayermatch.org"
          target="_blank"
          rel="noopener noreferrer"
        >
                <p className="p1"  align="left">
          prayers
        </p>
        </a>
                  <p  className="scanTo">
          Scan to pray
        </p>
        <div className="container_row">
            <div className="rectang">
                        </div>
             <img 
      src={fire.prayercode}
      className="nuemorphicCode" 
      alt="error"
      />
                 <img src={logo} className="codeLogo" alt="logo" />
          </div>

          <div class="flexbox-container">
                 <img src={fireLogo} className="fire" alt="fire" />
                    <h  className="prayertag">{fire.fireName}</h>

</div>
          <p  className="displayName">
          ⓟ{user.prayertag}
        </p>
        <a
          href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={appStore} className="nuemorphicBadge" alt="logo" />
                </a>
      </header>
    </div>
      }
    }
  }
}