 import logo from './logo.svg';
import appStore from './AppStore.png';
import './App.css';
import { Helmet } from "react-helmet";

export function 
    Home(){
        return <div className="App">
                                        {/* <Helmet>   
                                <title>I prayed for you.</title>
                     <meta property="og:title" content="I prayed for you." />
    <meta property="og:image"
        content="https://firebasestorage.googleapis.com/v0/b/prayermatch-app.appspot.com/o/DevFiles%2FHearlight.png?alt=media&token=1e6ee554-cde5-43a5-9ff4-69a166af11c4" />
                     </Helmet> */}
        <header className="App-header">
                    <a
            href="https://prayermatch.org"
            target="_blank"
            rel="noopener noreferrer"
            >
                    <p className="p1"  align="left">
            prayers
            </p>
            </a>
                            <img src={logo} className="shimmerLogo" alt="shimmerLogo" />
                <h  className="pct">
            Prayer changes things.
            </h>
            <a
            href="https://apps.apple.com/us/app/prayers-send-receive-listen/id1667162145"
            target="_blank"
            rel="noopener noreferrer"
            >
            <img src={appStore} className="nuemorphicBadgeHome" alt="logo" />
                    </a>
        </header>
        </div> 
    }