import './App.css';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

export function 
    PhoneListening(){
const {phoneNumber} = useParams()

const auth = getAuth();

// window.AnalyserNoderecaptchaVerifier.render().then((widgetId) => {
//   window.recaptchaWidgetId = widgetId;
// });
// const recaptchaResponse = grecaptcha.getResponse(recaptchaWidgetId);

function sendCode(){

    
const recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
  'size': 'invisible',
  'callback': (response) => {
    // reCAPTCHA solved, allow signInWithPhoneNumber.
    var formatedPhoneNumber = `+1${phoneNumber}`
signInWithPhoneNumber(auth, formatedPhoneNumber, recaptchaVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      // ...
    }).catch((error) => {
        console.log(error.message)
        window.alert(`${error.code}, ${error.message}`);
        recaptchaVerifier.reset();
    });
  }
}, auth);

}
        return <div className="App">
                                        <Helmet>   
                                <title>I prayed for you.</title>
                     <meta property="og:title" content="I prayed for you." />
    <meta property="og:image"
        content="https://firebasestorage.googleapis.com/v0/b/prayermatch-app.appspot.com/o/DevFiles%2FHearlight.png?alt=media&token=1e6ee554-cde5-43a5-9ff4-69a166af11c4" />
                     </Helmet>
        <header className="App-header">
                    <a
            href="https://prayermatch.org"
            target="_blank"
            rel="noopener noreferrer"
            >
                    <p className="p1"  align="left">
            prayers
            </p>
            </a>
                <h  className="pct">
            Send one-time-code.
            </h>
                      <p  className="displayName">
          {phoneNumber}
        </p>
        <button id="sign-in-button" onClick={sendCode}>Click Me!</button>
        </header>
        </div> 
    }