
import { initializeApp } from "firebase/app"
import { initializeFirestore } from "firebase/firestore"
const { initializeAppCheck, ReCaptchaEnterpriseProvider } = require("firebase/app-check");

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET
}


const app = initializeApp(config)
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_APPCHECKID),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true, // this line
  useFetchStreams: false, // and this line
})