import './App.css';
import { Route, Routes, BrowserRouter} from 'react-router-dom';
import { PrayerLanding } from './PrayerLanding';
import { FireLanding } from './FireLanding';
import { PhoneListening } from './PhoneListening';
import { Home } from './Home';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/for/:phoneNumber" element={<PhoneListening />} />
      <Route path="/:prayertag" element={<PrayerLanding />} />
      <Route path="/:prayertag/:fireName" element={<FireLanding />} />
    </Routes>
  );
}

export default App;
